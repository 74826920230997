/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IMessage } from '@flyaps/chat-widget/lib/components/ChatWidget/ChatWidget.models';
import { initialMessage } from './constants';
import postGuideChatMessage from './api.service';
import { addBotMessage, addUserMessage, addBotErrorMessage } from './utilities';
// eslint-disable-next-line no-unused-vars
import { IGuideChatCreateMessageParams } from './GuideChat.models';

export interface IState {
  data: IMessage[]
  isLoading: boolean
  error: object | null
}

const initialState: IState = {
  error: null,
  isLoading: false,
  data: [initialMessage],
};

export const postGuideChatMessageAction = createAsyncThunk(
  'postGuideChatMessage',
  async (params: IGuideChatCreateMessageParams, { rejectWithValue }) => {
    try {
      const { data } = await postGuideChatMessage(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const GuideChatSlice = createSlice({
  name: 'GuideChat',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(postGuideChatMessageAction.pending, (state, action) => {
        state.error = null;
        state.isLoading = true;
        state.data = addUserMessage(action.meta.arg, state.data);
      })
      .addCase(postGuideChatMessageAction.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.data = addBotMessage(action.payload, state.data);
      })
      .addCase(postGuideChatMessageAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = addBotErrorMessage(state.data);
      });
  },
});

export const { clear: clearGuideChatAction } = GuideChatSlice.actions;

export const guideChatReducer = GuideChatSlice.reducer;
