import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import { numericStatusOptions } from 'shared/constants';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import { SELLER_LABELS } from '@EngagementsForm/EngagementsFormGeneral/constants';
import { useAppSelector } from 'core/store';

const formatArrayIntoLine = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};

type TFormik = { selectedCustomer: ICustomerListItem | null }

const EngagementsFormGeneralCustomerInformation = () => {
  const { values: { selectedCustomer } } = useFormikContext<TFormik>();
  const brandName = useAppSelector((state) => state.appVariables.data.brand);

  if (!selectedCustomer) return null;

  return (
    <Grid
      className="engagement-forms-edit__left-side"
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
      pb={3}
    >
      <div className="engagement-forms-edit__left-side-container">
        <FormikRadioGroup
          fieldName="selectedCustomer.status"
          options={numericStatusOptions}
          label="Customer Status"
          disabled
        />
        <FormikTextField
          className="engagement-forms-edit__left-side-container_item"
          fieldName="selectedCustomer.subAccountId"
          label="Sub-Account ID"
          variant="standard"
          disabled
        />
        <FormikTextField
          className="engagement-forms-edit__left-side-container_item"
          fieldName="selectedCustomer.seller"
          formatValue={formatArrayIntoLine}
          label={SELLER_LABELS[brandName] || 'Seller'}
          variant="standard"
          disabled
        />
        <FormikTextField
          className="engagement-forms-edit__left-side-container_item"
          fieldName="selectedCustomer.projectManagers"
          formatValue={formatArrayIntoLine}
          label="Project Manager"
          variant="standard"
          disabled
        />
      </div>
    </Grid>
  );
};

export default EngagementsFormGeneralCustomerInformation;
