// eslint-disable-next-line no-unused-vars
import React, { FC, useState, ChangeEvent } from 'react';
import { noop } from 'lodash';
import {
  SvgIconTypeMap,
  TextField,
  InputAdornment,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useField } from 'formik';
import CustomTooltip from 'shared/CustomTooltip';
import FormikTextFieldEndAdornment from './FormikTextFieldEndAdornment';
import FormikFieldWrapper from '../FormikFieldWrapper';
import { FORMIK_TEXT_FIELD_TYPE } from './FormikTextField.model';
import { GRID_DIRECTION } from '../FormikField.model';
import '../FormikField.scss';
import './FormikTextField.scss';

type TextFieldVariant = 'outlined' | 'standard' | 'filled';

type TFormatValue = (value: string | number | Date | string[]) => string | number | Date | null;

interface IFormikTextFieldProps {
  className?: string | undefined
  label?: string
  fieldName: string
  placeholder?: string | undefined
  formatValue?: TFormatValue | null
  variant?: TextFieldVariant
  type?: string | undefined
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
  isTooltip?: boolean
  emptyRightSideSize?: number | undefined
  gridDirection?: GRID_DIRECTION
  onInputChange?: (value?: string) => void
  StartIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined
}

const getTextFieldType = (type: string | undefined, isShowInputValue: boolean) => {
  const isTypePassword = type === FORMIK_TEXT_FIELD_TYPE.PASSWORD;

  if (isTypePassword) {
    if (isShowInputValue) {
      return FORMIK_TEXT_FIELD_TYPE.TEXT;
    }
    return FORMIK_TEXT_FIELD_TYPE.PASSWORD;
  }

  return type;
};

const FormikTextField: FC<IFormikTextFieldProps> = ({
  className,
  label,
  fieldName,
  placeholder,
  emptyRightSideSize,
  variant,
  formatValue,
  type,
  disabled,
  required,
  fullWidth,
  isTooltip,
  StartIcon,
  onInputChange,
  gridDirection,
}) => {
  const [field, error] = useField(fieldName);
  const { onChange, ...fieldProps } = field;

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const isTypePassword = type === FORMIK_TEXT_FIELD_TYPE.PASSWORD;
  const [isShowInputValue, setIsShowInputValue] = useState(!isTypePassword);
  const toggleShowInputValue = () => setIsShowInputValue((isShow) => !isShow);

  const startAdornment = StartIcon ? (
    <InputAdornment position="start">
      <StartIcon />
    </InputAdornment>
  ) : null;

  const endAdornment = (
    <FormikTextFieldEndAdornment
      type={type}
      isShowPassword={isShowInputValue}
      toggleIsShowPassword={toggleShowInputValue}
    />
  );

  const inputType = getTextFieldType(type, isShowInputValue);

  const value = formatValue?.(field.value) || field.value || '';

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onInputChange?.(event.target.value);
    onChange?.(event);
  };

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      required={required as boolean}
      gridDirection={gridDirection as GRID_DIRECTION}
      emptyRightSideSize={emptyRightSideSize}
    >
      <CustomTooltip
        title={value}
        isHidden={!isTooltip}
      >
        <TextField
          {...fieldProps}
          className={className as string}
          value={value}
          id={fieldName}
          placeholder={placeholder as string}
          type={inputType}
          error={isError}
          helperText={errorText}
          onChange={onChangeHandler}
          disabled={disabled as boolean}
          required={required as boolean}
          fullWidth={fullWidth as boolean}
          variant={variant as TextFieldVariant}
          InputProps={{ startAdornment, endAdornment }}
        />
      </CustomTooltip>
    </FormikFieldWrapper>
  );
};

FormikTextField.defaultProps = {
  className: '',
  formatValue: null,
  variant: 'outlined',
  disabled: false,
  required: false,
  fullWidth: false,
  isTooltip: false,
  emptyRightSideSize: undefined,
  type: undefined,
  label: '',
  placeholder: undefined,
  StartIcon: undefined,
  onInputChange: noop,
  gridDirection: GRID_DIRECTION.ROW,
};

export default FormikTextField;
