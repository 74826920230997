import React, {
  useMemo, useState, memo, useEffect,
} from 'react';
import Loader from 'shared/Loader';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { ACTIONS_WIRELINE_ENGAGEMENTS_PATHS } from 'core/models/actionsPermissions';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import { updateEngagementScopeDescriptionNotifications } from 'pages/WirelineEngagements/WirelineEngagementsCreate/constants';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import scopeDescriptionSchema from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescriptionSchema';
import EngagementsFormScopeDescription from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/EngagementsFormScopeDescription';
// eslint-disable-next-line no-unused-vars
import { IScopeDescription, IScopeDescriptionForm } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';
import { editScopeDescriptionAction, clearEditScopeDescriptionAction } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/EditScopeDescription';
import { createScopeDescriptionAction, clearCreateScopeDescriptionAction } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/CreateScopeDescription';

const {
  SCOPE_DESCRIPTION_CREATE,
  SCOPE_DESCRIPTION_PARTIAL_EDIT,
} = ACTIONS_WIRELINE_ENGAGEMENTS_PATHS;

const actionsPaths = {
  isAvailableCreateScopeDescription: SCOPE_DESCRIPTION_CREATE,
  isAvailableEditScopeDescription: SCOPE_DESCRIPTION_PARTIAL_EDIT,
};

const ScopeDescription = () => {
  const dispatch = useAppDispatch();
  const { wirelineEngagementId } = useParams();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const { engagementsInitialValues } = useWirelineEngagementsCreateContext();
  const { scopeDescription: initialScopeDescriptionData } = engagementsInitialValues || {};
  const { id: scopeDescriptionId } = initialScopeDescriptionData || {};

  const {
    isAvailableEditScopeDescription,
    isAvailableCreateScopeDescription,
  } = useActionsPermissions(actionsPaths);

  const isLoadingEditData = useAppSelector(
    (state) => state.wirelineEngagements.editScopeDescription.isLoading,
  );
  const isLoadingCreateData = useAppSelector(
    (state) => state.wirelineEngagements.createScopeDescription.isLoading,
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [scopeDescriptionData, setScopeDescriptionData] = useState<IScopeDescription>(
    {} as IScopeDescription,
  );

  const isLoading = isLoadingEditData || isLoadingCreateData;

  const isVisibleActionButton = (scopeDescriptionData?.id && isAvailableEditScopeDescription)
  || (!scopeDescriptionData?.id && isAvailableCreateScopeDescription);

  const handleErrorSaveData = (error, { setErrors }, errorMessage) => {
    const definedError = error as IServerValidationError;
    setErrors(definedError?.response?.data);

    showErrorNotifier(errorMessage, error);
  };

  const updateScopeDescription = (params: IScopeDescriptionForm) => (scopeDescriptionData?.id
    ? dispatch(editScopeDescriptionAction({
      params,
      urlParams: {
        id: scopeDescriptionData.id as number,
        engagementId: wirelineEngagementId as string,
      },
    }))
    : dispatch(createScopeDescriptionAction({
      params,
      engagementId: wirelineEngagementId as string,
    }))
  );

  const onSubmit = async (params, formikHelpers) => {
    const isDescriptionChanged = params.text
      !== scopeDescriptionData?.text;

    if (!isDescriptionChanged) {
      setIsEditMode(false);
      return;
    }

    try {
      const data = await updateScopeDescription(params).unwrap();

      showSuccessNotifier(updateEngagementScopeDescriptionNotifications.success);
      setIsEditMode(false);
      setScopeDescriptionData(data);
    } catch (error) {
      handleErrorSaveData(
        error,
        formikHelpers,
        updateEngagementScopeDescriptionNotifications.error,
      );
    }
  };

  const updateScoprDescriptionData = () => {
    if (!initialScopeDescriptionData || !wirelineEngagementId) { return; }
    setScopeDescriptionData(initialScopeDescriptionData);
  };

  const initialValues = useMemo(() => (
    isEmpty(scopeDescriptionData)
      ? scopeDescriptionSchema.getDefault()
      : { text: scopeDescriptionData.text }
  ), [scopeDescriptionData]);

  useEffect(() => {
    updateScoprDescriptionData();

    return () => {
      dispatch(clearCreateScopeDescriptionAction());
      dispatch(clearEditScopeDescriptionAction());
    };
  }, [scopeDescriptionId, wirelineEngagementId]);

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={scopeDescriptionSchema}
    >
      <Form className="scope-description__wrapper">
        {isLoading && <Loader type="component" />}
        <EngagementsFormScopeDescription
          isEditMode={isEditMode}
          toggleEditMode={setIsEditMode}
          scopeDescription={scopeDescriptionData}
          isVisibleActionsButton={isVisibleActionButton}
        />
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

export default memo(ScopeDescription);
