import React, { useEffect, useMemo } from 'react';
import ButtonMenu, { MenuItems } from 'shared/ButtonMenu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getUserGuideAction } from 'layouts/Header/UserGuide/GetUserGuide/GetUserGuide';
import { useAppDispatch, useAppSelector } from 'core/store';
import { Tooltip } from '@mui/material';

interface IUserGuideMenuButton {
  onSelect: (type: string) => void;
}

const UserGuideMenuButton: React.FC<IUserGuideMenuButton> = ({
  onSelect,
}) => {
  const dispatch = useAppDispatch();
  const guideMenus = useAppSelector(
    (state) => state.users.userGuideFiles.result,
  );

  useEffect(() => {
    dispatch(getUserGuideAction());
  }, []);

  const menuItems = useMemo(() => (
    guideMenus.map((menu) => ({
      label: menu.name,
      onClick: () => onSelect(menu.file),
    }))
  ), [guideMenus]);

  return (
    <ButtonMenu
      menuClassName="requests-actions-menu"
      buttonContent={(
        <Tooltip title="Information guide">
          <InfoOutlinedIcon />
        </Tooltip>
      )}
      buttonVariant="text"
      isDisabled={false}
    >
      <MenuItems menuItems={menuItems} />
    </ButtonMenu>
  );
};

export default UserGuideMenuButton;
