import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import accessNames from 'core/routing/accessRoutesNames';
import { useParams, useNavigate } from 'react-router-dom';
import { useAvailableTabs } from 'core/routing/useAvailableRoutes';
import { ENGAGEMENT_TABS, WIRELINE_ENGAGEMENTS_TABS } from 'core/models/actionsPermissions';
import { getActiveNumbersPath, getRenewalSowPath, getSowDataPath } from 'core/routing/paths';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/EngagementFormsWrapper.scss';

const EngagementsFormScopeDescriptionActions = () => {
  const navigate = useNavigate();
  const { wirelineEngagementId } = useParams();
  const availableButtonsIds = useAvailableTabs(
    [accessNames.wirelineEngagements, WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT],
  );
  const { isEditModeGeneral, initialCustomerId } = useWirelineEngagementsCreateContext();

  const isCreateMode = !wirelineEngagementId;
  const isButtonDisabled = isCreateMode || isEditModeGeneral;

  const navigateToSowData = () => {
    navigate(getSowDataPath(wirelineEngagementId, initialCustomerId));
  };

  const navigateToActiveNumbers = () => {
    navigate(getActiveNumbersPath(wirelineEngagementId, initialCustomerId));
  };

  const navigateToRenewalSow = () => {
    navigate(getRenewalSowPath(wirelineEngagementId, initialCustomerId));
  };

  const availableButtons = useMemo(() => {
    const buttonsList = [
      {
        title: 'RENEWAL SOW',
        action: navigateToRenewalSow,
        id: ENGAGEMENT_TABS.RENEWAL_SOW,
      },
      {
        title: 'SOW DATA',
        action: navigateToSowData,
        id: ENGAGEMENT_TABS.SOW,
      },
      {
        title: 'ACTIVE NUMBERS',
        action: navigateToActiveNumbers,
        id: ENGAGEMENT_TABS.ACTIVE_NUMBERS,
      },
    ];

    return buttonsList.filter((button) => availableButtonsIds.includes(button.id));
  },
  [availableButtonsIds]);

  return (
    <>
      {availableButtons.map(({ title, action }) => (
        <Button
          className="action-button"
          onClick={action}
          variant="outlined"
          sx={{ mr: 2, mt: 3 }}
          disabled={isButtonDisabled}
        >
          {title}
        </Button>
      ))}
    </>
  );
};

export default EngagementsFormScopeDescriptionActions;
