import uniqueId from 'lodash/uniqueId';
// eslint-disable-next-line no-unused-vars
import { IMessage } from '@flyaps/chat-widget/lib/components/ChatWidget/ChatWidget.models';
import { errorMessage } from './constants';
// eslint-disable-next-line no-unused-vars
import { IGuideChatCreateMessageParams, IGuideChatCreateMessage } from './GuideChat.models';

export const addUserMessage = (
  userMessageData: IGuideChatCreateMessageParams,
  messages: IMessage[],
): IMessage[] => {
  const newMessage = {
    id: uniqueId('user_message_'),
    text: userMessageData?.message || '',
    isBot: false,
  };

  return [...messages, newMessage];
};

export const addBotMessage = (
  botMessageData: IGuideChatCreateMessage,
  messages: IMessage[],
): IMessage[] => {
  const newMessage = {
    id: uniqueId('bot_message_'),
    text: botMessageData?.response || '',
    sources: botMessageData?.sources || [],
    isBot: true,
  };

  return [...messages, newMessage];
};

export const addBotErrorMessage = (messages: IMessage[]) => {
  const newMessage = {
    id: uniqueId('bot_message_error_'),
    text: errorMessage,
    isBot: true,
  };

  return [...messages, newMessage];
};
