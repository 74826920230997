// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import { numericStatusOptions } from 'shared/constants';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import EngagementsFormGeneralCompanyNameSearch from './EngagementsFormGeneralCompanyNameSearch';
import EngagementsFormGeneralTechnicalOverlay from './EngagementsFormGeneralTechnicalOverlay';
import './EngagementsFormGeneral.scss';

interface IEngagementsFormGeneralEngagementProps {
  onSave: () => void;
}

const EngagementsFormGeneralEngagement = ({ onSave }: IEngagementsFormGeneralEngagementProps) => {
  const { wirelineEngagementId } = useParams();
  const { isEditModeGeneral } = useWirelineEngagementsCreateContext();
  const isCreateMode = !wirelineEngagementId;
  const isFieldsDisabled = !isEditModeGeneral && !isCreateMode;

  return (
    <Grid
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
      pb={3}
    >
      <FormikRadioGroup
        fieldName="engagementType"
        options={numericStatusOptions}
        label="Engagement type"
        required
        disabled={isFieldsDisabled}
      />
      <EngagementsFormGeneralCompanyNameSearch />
      <FormikTextField
        fieldName="subAgentName"
        label="SubAgent Name"
        placeholder="Enter text"
        emptyRightSideSize={2}
        disabled={isFieldsDisabled}
      />
      <EngagementsFormGeneralTechnicalOverlay />
      <FormikTextField
        fieldName="bdsSupport"
        label="BDR Support"
        placeholder="Enter text"
        emptyRightSideSize={2}
        disabled={isFieldsDisabled}
      />
      <FormikTextField
        fieldName="directSeller"
        label="Direct Seller"
        placeholder="Enter text"
        emptyRightSideSize={2}
      />
      <Button
        className="saveButton"
        variant="contained"
        onClick={onSave}
        sx={{ height: '37px' }}
      >
        SAVE
      </Button>
    </Grid>
  );
};

export default EngagementsFormGeneralEngagement;
