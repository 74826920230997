import { DASHBOARD_TABS, SELLER_DEPARTMENT_TABS } from 'pages/Dashboard/Dashboard.models';

export enum AUTORIZED_MODULE {
  CUSTOMERS = 'customers',
  DASHBOARD = 'dashboard',
  USER_MANAGEMENT = 'user_management',
  MOBILITY_REQUEST = 'mobility_requests',
  WIRELINE_ENGAGEMENTS = 'wireline_engagements',
  MOBILITY_ENGAGEMENTS = 'mobility_engagements',
  INSIDE_SALES_REAUEST = 'inside_sales_requests',
  CUSTOMER_SUPPORT_REQUEST = 'customer_support_requests',
  CONTRACT_MANAGEMENT = 'contract_management',
}

export enum WIRELINE_ENGAGEMENTS_TABS {
  ENGAGEMENT = 'engagement',
}

export enum ENGAGEMENT_TABS {
  SOW = 'sow',
  RENEWAL_SOW = 'renewal_sow',
  ACTIVE_NUMBERS = 'active_numbers',
}

export enum ACTIONS_METHOD {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum ACTIONS {
  MRC = 'mrc',
  BOOK = 'book',
  ORDER = 'order',
  TABLE = 'table',
  TOTAL = 'total',
  QUOTE = 'quote',
  EXPORT = 'export',
  REPORT = 'report',
  SELLER = 'seller',
  STATUS = 'status',
  REQUEST = 'request',
  COMMENT = 'comment',
  RENEWAL = 'renewal',
  BY_USERS = 'byUsers',
  CUSTOMER = 'customer',
  LOCATION = 'location',
  CONTRACT = 'contract',
  PROPOSAL = 'proposals',
  BY_PERIOD = 'byPeriod',
  ORDER_DATA = 'orderData',
  BY_REVENUE = 'byRevenue',
  ENGAGEMENT = 'engagement',
  ATTACHMENT = 'attachment',
  ATTACHMENTS = 'attachments',
  CANCEL_ORDER = 'cancelOrder',
  SERVICE_TYPE = 'serviceType',
  REQUEST_TYPE = 'requestType',
  ORDER_REQUEST = 'orderRequest',
  RENEWAL_ORDER = 'renewalOrder',
  OPEN_REQUESTS = 'openRequests',
  CANCEL_REQUEST = 'cancelRequest',
  REQUEST_STATUS = 'requestStatus',
  PROJECT_STATUS = 'projectStatus',
  DECLINE_RENEWAL = 'declineOrder',
  ENGAGEMENT_TYPE = 'engagementType',
  ESCALATION_TYPE = 'escalationType',
  DUPLICATE_ORDER = 'duplicateOrder',
  REQUEST_ACCEPTED = 'requestAccepted',
  REQUEST_COMPLETED = 'requestCompleted',
  ENGAGEMENT_ACCEPT = 'engagementAccept',
  TECHNICAL_OVERLAY = 'technicalOverlay',
  SCOPE_DESCRIPTION = 'scopeDescription',
  ENGAGEMENT_STATUS = 'engagementStatus',
  PROJECT_ACCEPTANCE = 'projectAcceptance',
  ESCALATION_SUPPORT = 'escalationSupport',
  CONTRACT_SPECIALIST = 'contractSpecialist',
  ENGAGEMENT_ACCEPT_PM = 'engagementAcceptPm',
  INSIDE_SALES_REQUEST = 'insideSalesRequest',
  TRANSACTIONAL_ORDERS = 'transactionalOrders',
  ORDER_COMMENTS_GENERAL = 'orderCommentsGeneral',
  ORDER_COMMENTS_BILLING = 'orderCommentsBilling',
  ENGAGEMENT_ACCEPT_BILLING = 'engagementAcceptBilling',
}

export enum ACTIONS_CUSTOMERS_PATHS {
  SELLER_EDIT = `${AUTORIZED_MODULE.CUSTOMERS}.${ACTIONS.SELLER}.${ACTIONS_METHOD.PUT}`,
  CUSTOMER_EDIT = `${AUTORIZED_MODULE.CUSTOMERS}.${ACTIONS.CUSTOMER}.${ACTIONS_METHOD.PUT}`,
  CUSTOMER_CREATE = `${AUTORIZED_MODULE.CUSTOMERS}.${ACTIONS.CUSTOMER}.${ACTIONS_METHOD.POST}`,
}

export enum ACTIONS_WIRELINE_ENGAGEMENTS_PATHS {
  COMMENT_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.COMMENT}.${ACTIONS_METHOD.GET}`,
  LOCATION_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.LOCATION}.${ACTIONS_METHOD.GET}`,
  COMMENT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.COMMENT}.${ACTIONS_METHOD.POST}`,
  ATTACHMENT_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ATTACHMENT}.${ACTIONS_METHOD.GET}`,
  ENGAGEMENT_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ENGAGEMENT}.${ACTIONS_METHOD.GET}`,
  ENGAGEMENT_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ENGAGEMENT}.${ACTIONS_METHOD.PUT}`,
  ENGAGEMENT_PARTIAL_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ENGAGEMENT}.${ACTIONS_METHOD.PATCH}`,
  ENGAGEMENT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ENGAGEMENT}.${ACTIONS_METHOD.POST}`,
  ATTACHMENT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.ATTACHMENT}.${ACTIONS_METHOD.POST}`,
  SCOPE_DESCRIPTION_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.SCOPE_DESCRIPTION}.${ACTIONS_METHOD.GET}`,
  SCOPE_DESCRIPTION_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.SCOPE_DESCRIPTION}.${ACTIONS_METHOD.POST}`,
  SCOPE_DESCRIPTION_PARTIAL_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${ACTIONS.SCOPE_DESCRIPTION}.${ACTIONS_METHOD.PATCH}`,
}

export enum ACTIONS_WIRELINE_ENGAGEMENT_PATHS {
  ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ACTIONS.ORDER}.${ACTIONS_METHOD.POST}`,
}

export enum ACTIONS_WIRELINE_ENGAGEMENT_SOW_PATHS {
  QUOTE_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.QUOTE}.${ACTIONS_METHOD.POST}`,
  CONTRACT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.CONTRACT}.${ACTIONS_METHOD.POST}`,
  ORDER_DATA_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_DATA}.${ACTIONS_METHOD.GET}`,
  ORDER_PARTIAL_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER}.${ACTIONS_METHOD.PATCH}`,
  EXPORT_SOW_DATA_TABLE_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.EXPORT}.${ACTIONS_METHOD.GET}`,
  CANCEL_ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.CANCEL_ORDER}.${ACTIONS_METHOD.POST}`,
  ORDER_REQUEST_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_REQUEST}.${ACTIONS_METHOD.POST}`,
  DUPLICATE_ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.DUPLICATE_ORDER}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.GET}`,
  COMMENTS_BILLING_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.GET}`,
  ESCALATION_SUPPORT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ESCALATION_SUPPORT}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.POST}`,
  COMMENTS_BILLING_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.SOW}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.POST}`,
}

export enum ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS {
  QUOTE_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.QUOTE}.${ACTIONS_METHOD.POST}`,
  CONTRACT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.CONTRACT}.${ACTIONS_METHOD.POST}`,
  ORDER_DATA_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ORDER_DATA}.${ACTIONS_METHOD.GET}`,
  ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.RENEWAL_ORDER}.${ACTIONS_METHOD.POST}`,
  CANCEL_ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.CANCEL_ORDER}.${ACTIONS_METHOD.POST}`,
  ORDER_PARTIAL_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.RENEWAL_ORDER}.${ACTIONS_METHOD.PATCH}`,
  EXPORT_RENEWAL_SOW_TABLE_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.EXPORT}.${ACTIONS_METHOD.GET}`,
  DECLINE_RENEWAL_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.DECLINE_RENEWAL}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.GET}`,
  COMMENTS_BILLING_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.GET}`,
  ENGAGEMENT_ACCEPT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ENGAGEMENT_ACCEPT}.${ACTIONS_METHOD.POST}`,
  ESCALATION_SUPPORT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ESCALATION_SUPPORT}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.POST}`,
  COMMENTS_BILLING_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.RENEWAL_SOW}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.POST}`,
}

export enum ACTIONS_WIRELINE_ENGAGEMENT_ACTIVE_NUMBERS_PATHS {
  ORDER_DATA_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER_DATA}.${ACTIONS_METHOD.GET}`,
  ORDER_PARTIAL_EDIT = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER}.${ACTIONS_METHOD.PATCH}`,
  CANCEL_ORDER_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.CANCEL_ORDER}.${ACTIONS_METHOD.POST}`,
  EXPORT_ACTIVE_NUMBERS_TABLE_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.EXPORT}.${ACTIONS_METHOD.GET}`,
  COMMENTS_GENERAL_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.GET}`,
  COMMENTS_BILLING_GET = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.GET}`,
  ESCALATION_SUPPORT_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ESCALATION_SUPPORT}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.POST}`,
  COMMENTS_BILLING_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.POST}`,
  ENGAGEMENT_ACCEPT_PM_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ENGAGEMENT_ACCEPT_PM}.${ACTIONS_METHOD.POST}`,
  ENGAGEMENT_ACCEPT_BILLING_CREATE = `${AUTORIZED_MODULE.WIRELINE_ENGAGEMENTS}.${WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT}.${ENGAGEMENT_TABS.ACTIVE_NUMBERS}.${ACTIONS.ENGAGEMENT_ACCEPT_BILLING}.${ACTIONS_METHOD.POST}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_SALES_PATHS {
  MRC_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.MRC}.${ACTIONS_METHOD.GET}`,
  TOTAL_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.TOTAL}.${ACTIONS_METHOD.GET}`,
  BOOKING_TABLE_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.TABLE}.${ACTIONS_METHOD.GET}`,
  BOOK_ORDER_CREATE = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.BOOK}.${ACTIONS_METHOD.POST}`,
  BY_PERIOD_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.BY_PERIOD}.${ACTIONS_METHOD.GET}`,
  EXPORT_BOOGING_TABLE_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.EXPORT}.${ACTIONS_METHOD.GET}`,
  BOOGING_TABLE_REPORT_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.REPORT}.${ACTIONS_METHOD.GET}`,
  BY_REVENUE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.BY_REVENUE}.${ACTIONS_METHOD.GET}`,
  TECHNICAL_OVERLAY_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.TECHNICAL_OVERLAY}.${ACTIONS_METHOD.GET}`,
  RENEWAL_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.SALES}.${ACTIONS.RENEWAL}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_INSIDE_SALES_PATHS {
  BY_USERS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.INSIDE_SALES}.${ACTIONS.BY_USERS}.${ACTIONS_METHOD.GET}`,
  REQUEST_BOOKING_TABLE_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.INSIDE_SALES}.${ACTIONS.TABLE}.${ACTIONS_METHOD.GET}`,
  REQUEST_STATUS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.INSIDE_SALES}.${ACTIONS.REQUEST_STATUS}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_PROJECT_MANAGER_PATHS {
  PROJECT_STATUS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.PROJECT_MANAGER}.${ACTIONS.PROJECT_STATUS}.${ACTIONS_METHOD.GET}`,
  PROJECT_ACCEPTANCE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.PROJECT_MANAGER}.${ACTIONS.PROJECT_ACCEPTANCE}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_CONTRACT_SPECIALIST_PATHS {
  BOOKING_TABLE_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.CONTRACT_SPECIALIST}.${ACTIONS.TABLE}.${ACTIONS_METHOD.GET}`,
  SERVICE_TYPE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.CONTRACT_SPECIALIST}.${ACTIONS.SERVICE_TYPE}.${ACTIONS_METHOD.GET}`,
  OPEN_REQUESTS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.CONTRACT_SPECIALIST}.${ACTIONS.OPEN_REQUESTS}.${ACTIONS_METHOD.GET}`,
  TRANSACTIONAL_ORDERS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.CONTRACT_SPECIALIST}.${ACTIONS.TRANSACTIONAL_ORDERS}.${ACTIONS_METHOD.GET}`,
  ENGAGEMENT_TYPE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.CONTRACT_SPECIALIST}.${ACTIONS.ENGAGEMENT_TYPE}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_BILING_SPECIALIST_PATHS {
  REQUEST_TYPE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.BILLING}.${ACTIONS.REQUEST_TYPE}.${ACTIONS_METHOD.GET}`,
  ENGAGEMENT_STATUS_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.BILLING}.${ACTIONS.ENGAGEMENT_STATUS}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_WIRELINE_TECHNICAL_OVERLAY_PATHS {
  MRC_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.TECHNICAL_OVERLAY}.${ACTIONS.MRC}.${ACTIONS_METHOD.GET}`,
  TOTAL_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.TECHNICAL_OVERLAY}.${ACTIONS.TOTAL}.${ACTIONS_METHOD.GET}`,
  BY_PERIOD_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.TECHNICAL_OVERLAY}.${ACTIONS.BY_PERIOD}.${ACTIONS_METHOD.GET}`,
  BY_REVENUE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.WIRELINE}.${SELLER_DEPARTMENT_TABS.TECHNICAL_OVERLAY}.${ACTIONS.BY_REVENUE}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_DASHBOARD_ESCALATION_SUPPORT_PATHS {
  REQUEST_EDIT = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.ESCALATION_SUPPORT}.${ACTIONS.REQUEST}.${ACTIONS_METHOD.PUT}`,
  TOTAL_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.ESCALATION_SUPPORT}.${ACTIONS.TOTAL}.${ACTIONS_METHOD.GET}`,
  ESCALATION_SUPPORT_TABLE_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.ESCALATION_SUPPORT}.${ACTIONS.TABLE}.${ACTIONS_METHOD.GET}`,
  ESCALATION_TYPE_CHART_GET = `${AUTORIZED_MODULE.DASHBOARD}.${DASHBOARD_TABS.ESCALATION_SUPPORT}.${ACTIONS.ESCALATION_TYPE}.${ACTIONS_METHOD.GET}`,
}

export enum ACTIONS_INSIDE_SALES_PATHS {
  PROPOSALS_GET = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.PROPOSAL}.${ACTIONS_METHOD.GET}`,
  PROPOSAL_CREATE = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.PROPOSAL}.${ACTIONS_METHOD.POST}`,
  ATTACHMENTS_GET = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.ATTACHMENTS}.${ACTIONS_METHOD.GET}`,
  ATTACHMENT_CREATE = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.ATTACHMENTS}.${ACTIONS_METHOD.POST}`,
  STATUS_PARTIAL_EDIT = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.STATUS}.${ACTIONS_METHOD.PATCH}`,
  CANCEL_REQUEST_CREATE = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.CANCEL_REQUEST}.${ACTIONS_METHOD.POST}`,
  REQUEST_ACCEPTED_CREATE = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.REQUEST_ACCEPTED}.${ACTIONS_METHOD.POST}`,
  INSIDE_SALES_REQUEST_CREATE = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.INSIDE_SALES_REQUEST}.${ACTIONS_METHOD.POST}`,
  INSIDE_SALES_REQUEST_PATRIAL_EDIT = `${AUTORIZED_MODULE.INSIDE_SALES_REAUEST}.${ACTIONS.INSIDE_SALES_REQUEST}.${ACTIONS_METHOD.PATCH}`,
}

export enum ACTIONS_CONTRACT_MANAGEMENT_PATHS {
  ORDER_DATA_GET = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ORDER_DATA}.${ACTIONS_METHOD.GET}`,
  ATTACHMENTS_GET = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ATTACHMENTS}.${ACTIONS_METHOD.GET}`,
  ATTACHMENTS_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ATTACHMENTS}.${ACTIONS_METHOD.POST}`,
  REQUEST_ACCEPTED_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.REQUEST_ACCEPTED}.${ACTIONS_METHOD.POST}`,
  CONTRACT_MANAGEMENT_PARTIAL_EDIT = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.REQUEST}.${ACTIONS_METHOD.PATCH}`,
  REQUEST_COMPLETED_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.REQUEST_COMPLETED}.${ACTIONS_METHOD.POST}`,
  COMMENTS_BILLING_GET = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.GET}`,
  COMMENTS_GENERAL_GET = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.GET}`,
  CONTRACT_SPECIALIST_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.CONTRACT_SPECIALIST}.${ACTIONS_METHOD.POST}`,
  COMMENTS_BILLING_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ORDER_COMMENTS_BILLING}.${ACTIONS_METHOD.POST}`,
  COMMENTS_GENERAL_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ORDER_COMMENTS_GENERAL}.${ACTIONS_METHOD.POST}`,
  ESCALATION_SUPPORT_CREATE = `${AUTORIZED_MODULE.CONTRACT_MANAGEMENT}.${ACTIONS.ESCALATION_SUPPORT}.${ACTIONS_METHOD.POST}`,
}
