import React from 'react';
import cx from 'classnames';
import { Tooltip } from '@mui/material';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { useSidebarContext } from 'layouts/Sidebar/SidebarContext';
import './ToggleButton.scss';

interface IToggleButtonProps {
  isOpen: boolean
  toggleHandler: () => void
}

const ToggleButton = ({ isOpen, toggleHandler }) => {
  const { isCollapsed } = useSidebarContext();

  return (
    <Tooltip title={isCollapsed ? 'Help & Tips' : ''} placement="right-end">
      <button
        type="button"
        onClick={toggleHandler}
        className={cx('toggle_button', { active: isOpen })}
      >
        <div className="toggle_button__icon-wrapper">
          <LiveHelpOutlinedIcon />
        </div>
        <span>Help & Tips</span>
      </button>
    </Tooltip>
  );
};

export default ToggleButton;
