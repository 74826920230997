// eslint-disable-next-line no-unused-vars
import { IMessage } from '@flyaps/chat-widget/lib/components/ChatWidget/ChatWidget.models';

export const initialMessage: IMessage = {
  id: 'message-1',
  isBot: true,
  text: `Welcome to the FLAI Support Agent!
         I'm here to help you navigate and work efficiently in the system.
         Type your question below, and I'll guide you! 😊`,
};

export const errorMessage = 'We’re experiencing a temporary issue. Please try again in a moment!';
